<template>
    <modal :visible.sync="isVisible" :close-action="null" :is-shadow-closable="false" class="modal--contract-delete">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <div :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Confirmez-vous les modifications apportées au contrat ?
            </span>
          </div>
        </section>
        <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />

        <p class="mt-3 text-center text-dark-lighter">
          Vous avez souhaité modifier des données déjà saisies et enregistrer ces modifications. En cliquant sur le bouton « Valider », vos nouvelles informations remplaceront définitivement les précédentes.
        </p>

        <div class="flex justify-center mt-10">
          <form @submit.prevent="onSubmit">
            <div class="flex flex-col">
              <label
                for="title"
                :class="['form-label', 'font-bold']"
              >
                Note sur les modifications du contrat*
              </label>
              <input
                id="revisionNote"
                name="revisionNote"
                v-model="revisionNote"
                type="text"
                required
                :class="['form-input']"
              />
            </div>

            <div class="flex justify-center gap-x-5 my-10">
              <button type="button" class="btn btn-outline--primary-bluer" @click="isVisible = false">
                Annuler
              </button>
              <button type="submit" class="btn btn-secondary">
                VALIDER
              </button>
            </div>
          </form>
        </div>

      </div>
    </modal>
</template>

<script>
import Modal from '@/components/commons/Modal'
import axios from '@/axios'

export default {
  components: {
    Modal,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    isOpen(newVal) {
      this.isVisible = newVal
    },
    isVisible(newVal) {
      this.$emit('update:isOpen', newVal)
    }
  },

  data() {
    return {
      isVisible: false,
      loading: false,
      revisionNote: null,
    }
  },

  async beforeMount() {
    this.isVisible = this.isOpen
  },

  methods: {
    onSubmit() {
      this.isVisible = false
      this.$emit('submit', this.revisionNote)
    }
  }
}
</script>

<style lang="scss" scoped>
  form {
    width: 100%;
    max-width: 350px;
  }
</style>
