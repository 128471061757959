<template>
    <modal :visible.sync="isVisible" :close-action="null" :is-shadow-closable="false" class="modal--contract-delete">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <div :class="['container-header--title--secondary']">
            <icon icon="supprimer-secondary" size="sm" />
            <span :class="['bold']">
              Supprimer le contrat
            </span>
          </div>
        </section>
        <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />

        <p class="mt-3 text-center text-dark-lighter">
          La suppression du contrat en cliquant sur le bouton « Valider » entrainera sa suppression de la liste des contrats.
        </p>

        <div class="flex justify-center gap-x-5 my-10">
          <button class="btn btn-outline--primary-bluer" @click="isVisible = false">
            Annuler
          </button>
          <button class="btn btn-secondary" @click="onContractDelete">
            VALIDER
          </button>
        </div>
      </div>
    </modal>
</template>

<script>
import Modal from '@/components/commons/Modal'
import axios from '@/axios'

export default {
  components: {
    Modal,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    isOpen(newVal) {
      this.isVisible = newVal
    },
    isVisible(newVal) {
      this.$emit('update:isOpen', newVal)
    }
  },

  data() {
    return {
      isVisible: false,
      loading: false,
    }
  },

  async beforeMount() {
    this.isVisible = this.isOpen
  },

  methods: {
    /**
     * Delete contract function
     * @return {void}
     */
    async onContractDelete() {
      try {
        this.loading = true

        const res = await axios.delete(
          `${process.env.VUE_APP_API_VERSION}/contract/${this.$route.params.id}`,
          {
            headers: {
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token,
            },
          }
        )
        this.$toast.add({
          severity: 'success',
          summary: 'Succès',
          detail: res.data?.message || 'Succès',
          life: 5000,
        });
        await this.$router.push({ name: 'contracts' })
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: err.response?.data?.message || 'Une erreur est survenue',
          life: 5000,
        });
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
